import styled from 'styled-components';
import { mq } from 'helpers/stylehelpers';

/**
 * Erzeugt ein Grid
 */
const Grid = styled.div`
    display: grid;
    grid-gap: 1em;
    grid-template-columns: 100%;
    grid-template-rows: auto;

    ${mq.medium`
        grid-auto-flow: row dense;
        grid-template-columns: 1fr 1fr 1fr;

        & > * {
            /* Großes Element (links) */
            &:nth-child(3n + 1) {
                -ms-grid-column-span: 2;
                -ms-grid-row-span: 2;
                grid-column-end: span 2;
                grid-row-end: span 2;
            }
            /* Großes Element (rechts) */
            &:nth-child(6n + 4) {
                grid-column-start: 2;
            }
        }

    `};
`;

export default Grid;
