import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';

import { Highlight, P } from 'ui-kit/typography';
import Button, { ButtonGroup } from 'ui-kit/button';
import Homestage from 'ui-kit/homestage';
import Teaserbox from 'ui-kit/teaserbox';
import Intro from 'ui-kit/intro';
import Grid from 'ui-kit/grid';
import Section from 'ui-kit/section';
import Slider from 'ui-kit/slider';
import Container from 'ui-kit/container';
import Joblist from 'ui-kit/joblist';
import Image from 'ui-kit/image';
import { RecipeCard } from 'ui-kit/card';
import { getImageFromNode } from 'helpers/utils';

import Layout from '../components/layout';
import gridteaserRetailersearchImage from '../images/index-page/gridteaser-haendlersuche.svg';

/** Jobs */
const jobs = [
    {
        title: 'Auszubildender zum Kaufmann für Bürokommunikation (m/w)',
        id: 'bar',
    },
    {
        title: 'Auszubildender zum Kaufmann für Groß- und Außenhandel (m/w)',
        id: 'bar',
    },
    {
        title: 'Metzgermeister (m/w)',
        id: 'bar',
    },
    {
        title: 'Produktionsmitarbeiter (m/w)',
        id: 'bar',
    },
];

const IndexPage = ({ data }) => {
    const homestageBackgroundImage = data.homestageBackgroundImage.childImageSharp;
    const gridTeaserImageSmall = data.gridTeaserImageSmall.childImageSharp;
    const gridTeaserImageLarge = data.gridTeaserImageLarge.childImageSharp;
    const jobTeaserImage = data.jobsTeaserImage.childImageSharp;
    const { homestageTeaserImages, recipes } = data;

    /** Teaser-Cards für den Slider in der Startseite */
    const homestageCategoryTeasers = [
        <Teaserbox
            href="/herkunft-und-qualitaet"
            key="/herkunft-und-qualitaet"
            title="Herkunft & Qualität"
            image={
                getImageFromNode(
                    homestageTeaserImages,
                    'index-page/kategorieteaser-herkunft-und-qualitaet.jpg'
                ).node.childImageSharp
            }
            imageAlt="Landwirt, der ein Kalb auf dem Arm hält"
            centered
            noHover
            imageCritical
        />,
        <Teaserbox
            href="/rezepte"
            key="/rezepte"
            title="Rezepte"
            image={
                getImageFromNode(homestageTeaserImages, 'index-page/kategorieteaser-rezepte.jpg')
                    .node.childImageSharp
            }
            imageAlt="Appetitliches Gericht mit Knödeln"
            centered
            noHover
            imageCritical
        />,
        <Teaserbox
            href="/karriere/arbeiten-bei-uns"
            key="/karriere/arbeiten-bei-uns"
            title="Karriere"
            image={
                getImageFromNode(homestageTeaserImages, 'index-page/kategorieteaser-karriere.jpg')
                    .node.childImageSharp
            }
            imageAlt="Wanderer blickt auf Berggipfel"
            centered
            noHover
            imageCritical
        />,
    ];

    /** Rezept-Cards für den Slider */
    const recipeCards = recipes.edges.map(({ node: recipe }) => (
        <RecipeCard
            key={recipe.slug}
            layer={2}
            image={recipe.image.childImageSharp}
            difficulty={recipe.difficulty}
            time={recipe.time}
            title={recipe.title}
            tag={recipe.category}
            link={`/rezepte/${recipe.slug}`}
            imageCritical
        />
    ));

    return (
        <Layout description="Suedbayerische Fleischwaren">
            <Homestage
                intro={{
                    title: (
                        <>
                            <Highlight font="handwriting" className="homestage-serif">
                                Gutes
                            </Highlight>
                            von daheim
                        </>
                    ),
                    content:
                        'Sie lieben Fleisch- und Wurstwaren, die höchste Ansprüche erfüllen und Genießerherzen höher schlagen lassen? Wir auch. Herzlich willkommen bei der Südbayerischen Fleischwaren GmbH!',
                    cta: (
                        <>
                            <Button>Mehr über uns</Button>
                        </>
                    ),
                }}
                backgroundImage={homestageBackgroundImage}
                sliderElements={homestageCategoryTeasers}
            />
            <Section container="l" gap="xxl">
                <Intro title="Entdecken Sie die Südbayerische Fleischwaren GmbH" gap="xl">
                    <P fontSize="m">
                        Wir sind die richtige Adresse für hochwertige Spezialitäten und bieten das
                        Beste aus der Region. Selbst produziert – mit echter Leidenschaft, in großer
                        Vielfalt und ausgezeichneter Qualität.
                    </P>
                    <Highlight>Lassen Sie es sich schmecken!</Highlight>
                </Intro>
                <Grid>
                    <Teaserbox
                        href="/herkunft-und-qualitaet"
                        text="Sicherheit mit Prädikat: Das GQ-Siegel steht für bayerische Herkunft und lückenlos kontrollierte Qualität"
                        image={gridTeaserImageLarge}
                        imageAlt="Steak mit 'eingegrilltem' Logo Geprüfte Qualität Bayern"
                        layer={1}
                    />
                    <Teaserbox
                        href="/karriere/stellenangebote-und-ausbildungsberufe"
                        text="Starte deine berufliche Karriere: mit einer Ausbildung bei der Südbayerischen Fleischwaren GmbH"
                        image={gridTeaserImageSmall}
                        imageAlt="Vier unterschiedliche Auszubildene in selbstbewussten Posen"
                        layer={1}
                    />
                    <Teaserbox
                        href="/service-und-kontakt/haendlersuche"
                        image={gridteaserRetailersearchImage}
                        imageAlt="Händlersuche Pins auf einer illustrierten Karte"
                        layer={1}
                    />
                </Grid>
            </Section>
            <Section bg="pattern" gap="xxl">
                <Intro title="Probieren Sie unsere Rezeptideen" gap="xl">
                    <P gap="m" fontSize="m">
                        Ob festlich, bodenständig oder einfach schnell und köstlich – bei unseren
                        Rezepten ist für jeden Anlass und jeden Geschmack das richtige dabei. Lassen
                        Sie sich inspirieren und kochen Sie Ihre Favoriten einfach nach.
                    </P>
                    <Highlight>Viel Spaß und guten Appetit!</Highlight>
                </Intro>
                <Slider>{recipeCards}</Slider>
            </Section>
            <Section>
                <Intro title="Informieren Sie sich über aktuelle Stellenangebote" gap="xxl">
                    <P gap="m" fontSize="m">
                        In der Lebensmittelbranche herrscht immer Hochkonjunktur. Nutzen Sie die
                        Chance, verstärken Sie unser Team, freuen Sie sich auf einen sicheren,
                        abwechslungsreichen Arbeitsplatz
                    </P>
                    <Highlight>- und viele nette Kollegen!</Highlight>
                </Intro>
                <Container
                    grid
                    layout="2-3"
                    cols="2"
                    size="l"
                    alignItems="start"
                    gap={{ small: 'xxxl', large: 'xxxl' }}
                >
                    <Image
                        {...jobTeaserImage}
                        layer={1}
                        gap={{ small: 'xxl', medium: 'none' }}
                        alt="SBF Mitarbeiter mit Klemmbrett"
                    />
                    <Joblist items={jobs} limit={4} />
                </Container>
                <ButtonGroup display="flex" size="l" cols="2" justify="center">
                    <Button linkTo="/karriere/stellenangebote-und-ausbildungsberufe" size="m">
                        Alle Stellenangebote
                    </Button>
                    <Button
                        linkTo="/karriere/stellenangebote-und-ausbildungsberufe#ausbildungsberufe"
                        size="m"
                    >
                        Ausbildung bei SBF
                    </Button>
                </ButtonGroup>
            </Section>
        </Layout>
    );
};

/** Query für Page-Assets */
export const query = graphql`
    {
        homestageBackgroundImage: file(
            relativePath: { eq: "index-page/startseitenbuehne-hintergrundbild.jpg" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        homestageTeaserImages: allFile(
            filter: { relativePath: { glob: "index-page/kategorieteaser-*.jpg" } }
        ) {
            edges {
                node {
                    relativePath
                    childImageSharp {
                        fluid(maxWidth: 360, quality: 80) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
        gridTeaserImageLarge: file(relativePath: { eq: "index-page/gridteaser-gqsiegel.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 815, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        gridTeaserImageSmall: file(relativePath: { eq: "index-page/gridteaser-ausbildung.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 400, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        recipes: allRecipesJson(limit: 8) {
            edges {
                node {
                    slug
                    title
                    category
                    difficulty
                    time
                    image {
                        childImageSharp {
                            fluid(maxWidth: 430, quality: 80) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
        jobsTeaserImage: file(relativePath: { eq: "teaserboxes/teaserbox-stellenangebote.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 495, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;

IndexPage.propTypes = {
    data: PropTypes.shape({
        gridTeaserImageLarge: PropTypes.object,
        gridTeaserImageSmall: PropTypes.object,
        homestageTeaserImages: PropTypes.object,
        jobsTeaserImage: PropTypes.object,
        recipes: PropTypes.object,
        homestageBackgroundImage: PropTypes.object,
    }).isRequired,
};

export default IndexPage;
