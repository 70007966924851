import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { gapable, layerable } from 'helpers/traits';
import { mq } from 'helpers/stylehelpers';

import Button from './button';
import Dropdown from './dropdown';
import Icon from './icon';
import Navbar from './navbar';
import Search from './search';

/** Navbar angepasst */
const StyledNavbar = styled(Navbar)`
    padding: 1em 0.85em;

    ${mq.medium`
        padding: 0 1em;
    `};
`;

/** Navbar-Item angepasst */
const StyledNavbarItem = styled(Navbar.Item)`
    padding: 0.5em 0;

    &:first-child {
        padding-top: 0;
    }

    &:last-child {
        padding-bottom: 0;
    }

    ${({ reset }) => reset && ` ${Button} {width: 100%;}`};

    ${mq.medium`
        flex: 1 0 0;
        padding: 0.75em 0.5em;

        ${({ reset, text }) => (reset || text) && `flex: 0 1 auto; width: auto;`};

        &:first-child {
            padding-left: 0;
            padding-top: 0.75em;
        }

        &:last-child {
            padding-right: 0;
            padding-bottom: 0.75em;
        }

        ${({ border }) =>
            border &&
            `
                padding-right: 1em;
                margin-right: 0.5em;
            `}
    `};
`;

/**
 * Erzeugt eine Filter-Element
 *
 * @param {array} props.filters Die Filtermöglichkeiten ({ name: '...', filterFn: () => ..., options: [ ... ] })
 * @param {string} props.gap Optional: Der Abstand nach unten ('s', 'm', 'l', 'xl', 'xxl', 'xxxl')
 * @param {array} props.items Die zu filternden Elemente
 * @param {number} props.layer Optional: Die Ebene/Größe des Schattens
 * @param {string} props.mode Optional: Der logische Modus, in dem gefiltert werden soll ('AND', 'OR', 'XOR')
 * @param {function} props.render Eine Render-Funktion, mit der die Items im Container gerendert werden
 *
 * @example <Filter gap="l" layer={2}Test</Filter>
 */
class Filter extends Component {
    static propTypes = {
        filters: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                filterFn: PropTypes.func,
                options: PropTypes.arrayOf(
                    PropTypes.shape({
                        name: PropTypes.string.isRequired,
                        value: PropTypes.any.isRequired,
                    })
                ),
                type: PropTypes.oneOf(['link', 'search', 'select', 'text']),
            })
        ).isRequired,
        gap: gapable.propType,
        items: PropTypes.arrayOf(PropTypes.object).isRequired,
        layer: layerable.propType,
        mode: PropTypes.oneOf(['AND', 'OR', 'XOR']),
        render: PropTypes.func.isRequired,
    };

    static defaultProps = {
        gap: null,
        layer: null,
        mode: 'AND',
    };

    /**
     * Filter-Modi (AND, OR, XOR)
     * Note: XOR wird innerhalb der handleChange Funktion berücksichtigt
     */
    static MODES = {
        AND: filters => {
            const flat = filters.flat();
            const counter = new Map();
            const filtersLength = filters.length;

            flat.map(item => counter.set(item, (counter.get(item) || 0) + 1));

            return Array.from(counter)
                .filter(([, value]) => value === filtersLength)
                .map(([key]) => key);
        },
        OR: items => items.flat(),
        XOR: items => items[0] || [], // see "Note: ..." above
    };

    state = {
        filterValues: {},
        // eslint-disable-next-line react/destructuring-assignment
        filteredItems: this.props.items,
    };

    /**
     * Filtert die Elemente (props.items) anhand des values-Parameters,
     * der den Filternamen sowie den zugehörigen, ausgewählten Wert enthält
     * @param {object} values
     * @returns array
     *
     * @example filter({ Hauptzutat: "Wurst" })
     */
    filter(values) {
        const { items } = this.props;
        const selectedFilters = Object.keys(values);

        // Wenn kein Filter ausgewählt ist, einfach alle items zurückgeben
        if (!selectedFilters.length) {
            return items;
        }

        const { filters, mode } = this.props;
        return Filter.MODES[mode](
            filters
                .filter(filter => selectedFilters.includes(filter.name))
                .filter(({ filterFn }) => filterFn && typeof filterFn === 'function')
                .map(filter => items.filter(filter.filterFn(values[filter.name])))
        );
    }

    /**
     * Handle Change
     * @param {object} filter Name und Wert des Feldes ({ name: 'Haupzutat', value: 'Speck' })
     */
    handleChange(filter) {
        const { mode } = this.props;
        const { name, value } = filter;

        this.setState(({ filterValues }) => {
            // Im Modus XOR verwerfen wir alle bisher gesetzten Filter
            const newFilterValues = {
                ...(mode === 'XOR' ? {} : filterValues),
                [name]: value,
            };

            // Zurückgesetzte Filter löschen (im OR Modus relevant)
            if (filter.value === '*') {
                delete newFilterValues[filter.name];
            }

            return { filterValues: newFilterValues, filteredItems: this.filter(newFilterValues) };
        });
    }

    /**
     * Setzt den Filter komplett zurück
     * @example this.reset()
     */
    reset() {
        this.setState({ filterValues: {}, filteredItems: this.props.items }); // eslint-disable-line react/destructuring-assignment
    }

    /**
     * Rendert die Komponente
     */
    render() {
        const { gap, layer, filters, render, mode } = this.props;
        const { filteredItems, filterValues } = this.state;

        return (
            <div>
                <StyledNavbar gap={gap} layer={layer}>
                    {filters.map(el => {
                        switch (el.type) {
                            case 'search':
                                return (
                                    <StyledNavbarItem key={el.name} border search>
                                        <Search
                                            name={el.name}
                                            onSubmit={evt => {
                                                evt.preventDefault();
                                                this.handleChange({
                                                    name: el.name,
                                                    value: evt.currentTarget.elements.search.value,
                                                });
                                            }}
                                            value={filterValues[el.name] || ''}
                                            onChange={evt => {
                                                evt.preventDefault();
                                                this.handleChange({
                                                    name: el.name,
                                                    value: evt.currentTarget.value,
                                                });
                                            }}
                                            compact
                                            aria-label="Suche"
                                        />
                                    </StyledNavbarItem>
                                );

                            default:
                            case 'text':
                                return (
                                    <StyledNavbarItem key={el.name} largeOnly tinted text>
                                        {el.value}
                                    </StyledNavbarItem>
                                );

                            case 'link':
                                return (
                                    <StyledNavbarItem key={el.name} border>
                                        <Navbar.Link
                                            as="button"
                                            type="button"
                                            isActive={
                                                !!filterValues[el.name] ||
                                                (Object.keys(filterValues).length === 0 &&
                                                    el.value === '*')
                                            }
                                            onClick={evt => {
                                                evt.preventDefault();
                                                this.handleChange({
                                                    name: el.name,
                                                    value: el.value,
                                                });
                                            }}
                                        >
                                            {el.name}
                                        </Navbar.Link>
                                    </StyledNavbarItem>
                                );

                            case 'select':
                                return (
                                    <StyledNavbarItem key={el.name}>
                                        <Dropdown
                                            name={el.name}
                                            onChange={evt =>
                                                this.handleChange({
                                                    name: el.name,
                                                    value: evt.currentTarget.value,
                                                })
                                            }
                                            value={filterValues[el.name] || '*'}
                                            aria-label={el.name}
                                        >
                                            {el.options.map(option => (
                                                <option key={option.name} value={option.value}>
                                                    {option.name}
                                                </option>
                                            ))}
                                        </Dropdown>
                                    </StyledNavbarItem>
                                );
                        }
                    })}
                    {mode === 'XOR' ? null : (
                        <StyledNavbarItem reset>
                            <Button
                                type="button"
                                onClick={() => this.reset()}
                                size="s"
                                rounded
                                disabled={Object.values(filterValues).length === 0}
                                layer={0}
                            >
                                <Icon type="trash" />
                            </Button>
                        </StyledNavbarItem>
                    )}
                </StyledNavbar>
                {render(filteredItems)}
            </div>
        );
    }
}

export default Filter;
