import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import theme from 'styled-theming';

import { gapable, selfAlignable } from 'helpers/traits';
import { mq } from 'helpers/stylehelpers';
import { colors } from 'helpers/variables';

import Icon from './icon';

/** Theming */
const iconColor = theme('main', {
    sbf: colors.sbf.primary,
    vielLeicht: colors.vielLeicht.secondary,
});

/** Angepasstes Icons */
const StyledIcon = styled(Icon)`
    color: ${iconColor};
`;

/** ---------------------------------------------------------------------------
 * Stars
 */
const Stars = ({ difficulty, max = 4 }) => {
    let key = 1;
    return (
        <div className="recipemeta__stars">
            Schwierigkeit:{' '}
            {new Array(max).fill(null, 0, max).map((el, i) => {
                key += 1;
                return (
                    <StyledIcon
                        type={i < difficulty ? 'starSolid' : 'starRegular'}
                        color="primary"
                        key={`difficulty-${key}`}
                    />
                );
            })}
        </div>
    );
};

Stars.propTypes = {
    difficulty: PropTypes.number.isRequired,
    max: PropTypes.number,
};

Stars.defaultProps = {
    max: 4,
};

/**
 * Erzeugt die Darstellung für den Schwierigkeitsgrad und die Zubereitungszeit des Rezepts
 *
 * @param {string} props.alignSelf Optional: die Ausrichtung innerhalb des Parents
 * @param {number} props.difficulty Die Schwierigkeit (1 - 4)
 * @param {number} props.maxDifficulty Die maximale Schwierigkeitsstufe
 * @param {string} props.gap Optional: Der Abstand nach unten ('s', 'm', 'l', 'xl', 'xxl', 'xxxl')
 * @param {time} props.time Die Zubereitungszeit in Minuten
 *
 * @example <RecipeMeta gap="l" difficulty={4} maxDifficulty={5} time={30} />
 */
const RecipeMeta = styled(({ className, difficulty, time, maxDifficulty }) => (
    <div className={className}>
        <Stars difficulty={difficulty} max={maxDifficulty} />
        <div>Dauer: {time} min</div>
    </div>
))`
    display: inline-flex;
    font-size: ${rem(12)};

    ${gapable()};
    ${selfAlignable()};
    text-align: left;

    > .recipemeta__stars {
        margin-right: ${rem(10)};
    }

    ${mq.large`
        font-size: ${rem(13)};
    `};
`;

RecipeMeta.propTypes = {
    alignSelf: selfAlignable.propType,
    difficulty: PropTypes.number.isRequired,
    gap: gapable.propType,
    time: PropTypes.number.isRequired,
    maxDifficulty: PropTypes.number,
};

RecipeMeta.defaultProps = {
    alignSelf: null,
    gap: null,
    maxDifficulty: 4,
};

export default RecipeMeta;
