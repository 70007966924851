import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { padding, rem, borderRadius } from 'polished';

import { iconColor, primaryColor } from 'helpers/themehelpers';
import { borderRadiuses, colors } from 'helpers/variables';
import { gapable } from 'helpers/traits';
import { mq } from 'helpers/stylehelpers';

import Icon from './icon';

const SearchContainer = styled.form`
    border-bottom: 1px solid ${colors.gray300};
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    overflow: hidden;
    width: 100%;
    ${gapable()};

    ${({ compact }) =>
        compact ? null : mq.medium`border-bottom: none; border-radius: ${borderRadiuses.s}`};
`;

const SearchInput = styled.input`
    border: none;
    flex: 1 1 5em;
    min-width: 5em;
    ${padding('6px', '10px')};

    ${({ compact }) =>
        compact
            ? null
            : mq.medium`
        border: 1px solid ${colors.gray300};
        border-right: none;
        ${borderRadius('left', borderRadiuses.s)};`};
`;

const SearchButton = styled.button`
    align-items: center;
    background: ${colors.white};
    border: none;
    cursor: pointer;
    display: flex;
    colors: red;

    ${({ compact }) =>
        compact
            ? null
            : mq.medium`
        background: ${primaryColor};
        color: ${colors.white}
        padding: ${rem(7)} ${rem(12)};`};
`;

const StyledIcon = styled(Icon)`
    color: ${iconColor};
`;

/**
 * Erzeugt ein Suchfeld
 * @param {bool} props.compact Optional: Kompakte Darstellung, wie sie standardmäßig auch mobil eingesetzt wird
 * @param {string} props.gap Optional: Der Abstand nach unten ('s', 'm', 'l', 'xl', 'xxl', 'xxxl')
 * @param {function} props.onSubmit Die Submit Handler Funktion
 *
 * @example <Search gap="l" onSubmit={...} />
 */
const Search = ({ className, compact, gap, onSubmit, value, onChange, ...otherProps }) => (
    <SearchContainer className={className} onSubmit={onSubmit} compact={compact} gap={gap}>
        <SearchInput
            compact={compact}
            value={typeof onChange === 'function' ? value : undefined}
            onChange={typeof value === 'string' ? onChange : null}
            type="text"
            placeholder="Suchbegriff..."
            name="search"
            {...otherProps}
        />
        <SearchButton compact={compact} type="submit" aria-label="Suche durchführen">
            <StyledIcon type="search" />
        </SearchButton>
    </SearchContainer>
);

Search.propTypes = {
    className: PropTypes.string,
    compact: PropTypes.bool,
    gap: gapable.propType,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    value: PropTypes.string,
};

Search.defaultProps = {
    className: null,
    compact: false,
    gap: null,
    onChange: null,
    value: null,
};

export default Search;
