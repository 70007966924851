import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { rgba, rem } from 'polished';
import Img from 'gatsby-image';

import { fluidImage, fluidImageType } from 'helpers/prop-types';
import { colors, gaps, fontSizes } from 'helpers/variables';
import { mq } from 'helpers/stylehelpers';
import { gapable, layerable, fontSizable } from 'helpers/traits';
import { headlineColor } from 'helpers/themehelpers';

import { Highlight } from './typography';
import Button from './button';
import Tag from './tag';
import RecipeMeta from './recipe-meta';
import LinkWrapper from './link-wrapper';

/** Stylebarer Wrapper */
const Wrapper = styled(LinkWrapper)`
    background-color: ${colors.white};
    ${mq.medium`
        ${({ transparentBg }) =>
            transparentBg &&
            css`
                background-color: ${rgba(colors.white, 0.9)};
            `};
    `};
    color: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: ${({ button }) => (button ? '1.30em' : 0)};
    margin-top: ${({ tag, image }) => (tag && !image ? '1em' : 0)};
    text-decoration: none;
    text-align: center;
    ${gapable()};
    ${layerable(1)};
`;

/** Image-Wrapper */
const CardImage = styled(Img)`
    flex-shrink: 0;
    width: 100%;
`;

/** Tag-Wrapper */
const CardTag = styled(Tag)`
    ${fontSizes.xs};
    transform: translate(0, -50%);
`;

/** Content-Wrapper */
const CardContent = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding-bottom: ${({ spaceBottom }) => (spaceBottom ? '1.2em' : '0')};
    padding-left: 0.85em;
    padding-right: 0.85em;
    padding-top: ${({ spaceTop }) => (spaceTop ? '0.8em' : '0')};
    position: relative;
    text-align: center;

    width: 100%;

    &,
    > * {
        text-decoration: none;
    }

    ${mq.large`
        padding-left: 1em;
        padding-right: 1em;
    `};
`;

/** Card-Button */
const CardButton = styled(Button)`
    margin-top: 0em;
    transform: translate(0, 50%);

    &:active {
        transform: translate(0, calc(50% + 2px));
    }
`;

/**
 * Erzeugt eine Karte, wie sie bspw. für die Rezept-Teaser verwendet wird
 * @param {object} props.button: Optional: Ein Button unterhalb der Card ({ text: '...', onClick: () => ...})
 * @param {string} props.children Der Inhalt des Tags
 * @param {string} props.gap Optional: Der Abstand nach unten ('s', 'm', 'l', 'xl', 'xxl', 'xxxl')
 * @param {object} props.image Optional: Das Bild der Karte ((fluid) childImageSharp Object)
 * @param {string} props.imageAlt Optional: Alt-Text für das Bild
 * @param {number} props.layer Optional: Die Ebene/Größe des Schattens0
 * @param {string} props.link Das Link-Ziel der Card
 * @param {object} props.meta Optional: Die Meta-Infos (Fußzeile der Card)
 * @param {string} props.tag Optional: Der Tag
 * @param {string} props.title Der Titel der Card
 *
 * @example <Card gap="l" layer={2} tag="Lorem" title="Lorem ipsum">Test</Card>
 */
const Card = ({
    className,
    children,
    image,
    tag,
    link,
    button,
    imageCritical,
    imageAlt,
    gap,
    layer,
    transparentBg,
}) => (
    <Wrapper to={link} className={className} gap={gap} layer={layer} transparentBg={transparentBg}>
        {image ? (
            <CardImage
                fluid={image.fluid}
                critical={imageCritical}
                fadeIn={!imageCritical}
                alt={imageAlt}
            />
        ) : null}
        {tag ? <CardTag size="s">{tag}</CardTag> : null}
        <CardContent spaceTop={!tag} spaceBottom={!button}>
            {children}
        </CardContent>
        {button ? (
            <CardButton size="m" rounded noHover onClick={button.onClick}>
                {button.text}
            </CardButton>
        ) : null}
    </Wrapper>
);

Card.propTypes = {
    className: PropTypes.string,
    button: PropTypes.shape({
        text: PropTypes.string.isRequired,
        onClick: PropTypes.func,
    }),
    children: PropTypes.node.isRequired,
    gap: gapable.propType,
    image: fluidImageType,
    imageAlt: PropTypes.string,
    layer: layerable.propType,
    link: PropTypes.string.isRequired,
    tag: PropTypes.string,
    transparentBg: PropTypes.bool,
    imageCritical: PropTypes.bool,
};

Card.defaultProps = {
    className: null,
    button: null,
    gap: null,
    image: null,
    imageAlt: null,
    layer: null,
    tag: null,
    transparentBg: null,
    imageCritical: null,
};

export default Card;

const recipePadding = css`
    ${mq.mediumDown`
        padding-left: 0.5em;
        padding-right: 0.5em;
    `};
`;

/** Headline des Rezepts */
const RecipeTeaser = styled.div`
    margin-bottom: ${gaps.l};
    ${fontSizable('inherit')};
    ${recipePadding};
`;

/** Highlight */
const RecipeTitle = styled(Highlight)`
    color: ${headlineColor};
    margin-top: -0.25em;
    ${recipePadding};
`;

const StyledRecipeMeta = styled(RecipeMeta)`
    margin-top: ${rem(10)};
`;

/**
 * Erzeugt eine Karte, wie sie bspw. für die Rezept-Teaser verwendet wird
 * @param {object} props.button: Optional: Ein Button unterhalb der Card ({ text: '...', onClick: () => ...})
 * @param {string} props.children Der Inhalt der Card
 * @param {number} props.difficulty Der Schwierigkeitsgrad
 * @param {string} props.gap Optional: Der Abstand nach unten ('s', 'm', 'l', 'xl', 'xxl', 'xxxl')
 * @param {boolean} props.hero Optional: Als Hero-Element verwenden (großer Titel/Text)
 * @param {object} props.image Optional: Das Bild der Karte ((fluid) childImageSharp Object)
 * @param {number} props.layer Optional: Die Ebene/Größe des Schattens
 * @param {string} props.link Das Link-Ziel der Card
 * @param {string} props.tag Optional: Der Tag
 * @param {number} props.time Die Zubereitungszeit
 * @param {string} props.title Der Titel der Card
 *
 * @example
 *      <RecipeCard
 *          gap="l"
 *          layer={2}
 *          image={...}
 *          difficulty={3}
 *          time={30}
 *          title="Ansprechender Rezepttitel"
 *          tag="Braten"
 *          link="/rezepte/rezept-123"
 *      >
 *          Lorem Ipsum Teaser Text
 *      </RecipeCard>
 */
export const RecipeCard = ({
    children,
    tag,
    image,
    imageCritical,
    difficulty,
    time,
    title,
    button,
    hero,
    transparentBg,
    ...props
}) => (
    <Card
        tag={tag}
        image={image}
        imageCritical={imageCritical}
        button={button}
        imageAlt={`Angerichtes Gericht für das Rezept ${title}`}
        transparentBg={transparentBg}
        {...props}
    >
        <RecipeTitle textAlign="center" fontSize={hero ? 'h2' : null}>
            {title}
        </RecipeTitle>
        {children && <RecipeTeaser fontSize={hero ? 'h5' : null}>{children}</RecipeTeaser>}
        <StyledRecipeMeta difficulty={difficulty} maxDifficulty={4} time={time} />
    </Card>
);

RecipeCard.propTypes = {
    button: PropTypes.shape({
        text: PropTypes.string.isRequired,
        onClick: PropTypes.func,
    }),
    children: PropTypes.node,
    difficulty: PropTypes.number.isRequired,
    gap: gapable.propType,
    hero: PropTypes.bool,
    image: PropTypes.shape(fluidImage),
    imageCritical: PropTypes.bool,
    layer: layerable.propType,
    link: PropTypes.string.isRequired,
    tag: PropTypes.string,
    time: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    transparentBg: PropTypes.bool,
};

RecipeCard.defaultProps = {
    button: null,
    children: null,
    gap: null,
    hero: false,
    image: null,
    imageCritical: null,
    layer: null,
    tag: null,
    transparentBg: null,
};
